/* tslint:disable:max-line-length */
import { Injectable } from '@angular/core';
import { CognitoIdentityServiceProvider, AWSError } from 'aws-sdk';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { DatabaseService } from './database.service';
import { SettingsService } from './settings.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { User } from './models/user';
import { AnalyticsService } from './analytics.service';

/**
 * Duration before the expiry at which point which point we should attempt to refresh the ID Token
 */
const REFRESH_EXPIRY_TRIGGER = (1000 * 60 * 4); // 4 Minutes

/**
 * Interval at which we should check to see if we're past the refresh expiry trigger
 */
const REFRESH_CHECK_INTERVAL = (1000 * 10); // 10 Seconds

export interface CognitoChallenge {
    initiateAuthResponse: CognitoIdentityServiceProvider.Types.InitiateAuthResponse;
    password: string;
    username: string;
    userId: string;
}

export interface CognitoToken {
    accessToken: string;
    refreshToken: string;
    idToken: string;
    expiresAt: Date;
    loginExpiry: Date;
}

export type AuthChallenge = 'mfa' | 'new-password' | 'totp';

export interface AuthResponse {
    action: string;
    loggedIn: boolean;
    challengeType?: AuthChallenge;
}

@Injectable({
    providedIn: 'root'
})
export class CognitoAuthenticationService {

    private clientId: string = null;

    private cognito: CognitoIdentityServiceProvider = new CognitoIdentityServiceProvider({
        region: 'eu-west-2',
        accessKeyId: 'anything',
        secretAccessKey: 'anything'
    });

    private loginSubject = new Subject<CognitoIdentityServiceProvider.Types.InitiateAuthResponse>();
    private loginChallengeSubject = new Subject<CognitoIdentityServiceProvider.Types.InitiateAuthResponse>();
    private tokenSubject = new Subject<string>();
    private logoutSubject = new Subject<boolean>();
    private errorSubject = new Subject<AWSError>();
    private forgotPasswordSubject = new Subject<CognitoIdentityServiceProvider.Types.ForgotPasswordResponse>();
    private forgotPasswordChangedSubject = new Subject<CognitoIdentityServiceProvider.ConfirmForgotPasswordResponse>();
    private passwordChangedSubject = new Subject<CognitoIdentityServiceProvider.ChangePasswordResponse>();
    private cognitoUserSubject = new Subject<CognitoIdentityServiceProvider.Types.GetUserResponse>();
    private tokenRetrieved = new Subject<boolean>();
    private onReady: Subject<void> = new Subject<void>();

    private cognitoLogin: CognitoIdentityServiceProvider.Types.InitiateAuthRequest = null;
    private cognitoToken: CognitoToken = null;
    private cognitoChallenge: CognitoChallenge = null;
    private refreshCheck: Subscription;
    private forgotPasswordRequest: CognitoIdentityServiceProvider.Types.ForgotPasswordRequest = null;
    private forgotPasswordResponse: CognitoIdentityServiceProvider.Types.ForgotPasswordResponse = null;

    private showForgotPasswordSuccess = false;
    private showChangedPasswordSuccess = false;
    private cognitoUser: CognitoIdentityServiceProvider.Types.GetUserResponse = null;
    private loggedIn: boolean;
    private currentUser: User = null;

    private refreshTokenAttempt: Promise<string> = null;

    constructor(
        private analytics: AnalyticsService,
        private database: DatabaseService,
        private router: Router,
        private settings: SettingsService,
        private userService: UserService,
    ) {
        this.database.sharedReady().then(() => {
            this.setup();
        });

        this.onReady.asObservable().subscribe(() => {
            this.loggedIn = this.cognitoToken ? (!!this.cognitoToken.idToken) : false;
        });

        this.userService.getMe().pipe(
            distinctUntilChanged(),
        ).subscribe(async currentUser => {
            this.currentUser = currentUser;
            if (!this.loggedIn || this.currentUser === null) {
                return;
            }
            if (['developer', 'mediccoms_admin', 'mediccoms_user'].includes(this.currentUser?.role)) {
                this.logout().then(async () => await this.router.navigate(['login']));
            } else {
                this.analytics.setUserProperty('role', this.currentUser?.role);
            }
        });
    }

    public setClientId(clientId: string): void {
        this.clientId = clientId;
    }

    private setup() {
        this.retrieveTokens().then(response => {
            this.cognitoToken = response;
            if (this.cognitoToken.expiresAt > new Date()) {
                this.tokenSubject.next(this.cognitoToken.idToken);
                this.refreshCheck = this.setupRefreshTimer();
            } else if (this.cognitoToken.loginExpiry < new Date()) {
                this.logout();
            }
            this.onReady.next();
        }).catch(console.log);

        this.getError().subscribe((error: AWSError) => {
            // Sentry.captureException(error);
            if (error.message === 'Invalid Refresh Token') {
                this.logout();
            } else if (error.message === 'Refresh Token has expired') {
                this.logout();
            } else if (error.message === 'Access Token has expired') {
                this.logout();
            }
        });

        this.loginSubject.subscribe(() => {
            if (this.cognitoToken !== null) {
                this.refreshCheck = this.setupRefreshTimer();
                const request = {
                    AccessToken: this.cognitoToken.accessToken,
                };
                this.cognito.getUser(request, (error, response) => {
                    this.processGetUser(error, response);
                });
            }
        });

        this.loginChallengeSubject.subscribe(() => {
            if (this.cognitoToken !== null) {
                const request = {
                    AccessToken: this.cognitoToken.accessToken,
                };
                this.cognito.getUser(request, (error, response) => {
                    this.processGetUser(error, response);
                });
            }
        });

        this.tokenSubject.subscribe(() => {
            const request = {
                AccessToken: this.cognitoToken.accessToken,
            };
            this.cognito.getUser(request, (error, response) => this.processGetUser(error, response));
        });
    }

    private processGetUser(error: AWSError, response: CognitoIdentityServiceProvider.Types.GetUserResponse) {
        if (error) {
            this.errorSubject.next(error);
        } else {
            this.cognitoUser = response;
            this.cognitoUserSubject.next(response);
        }
    }

    private setupRefreshTimer(): Subscription {
        return timer(0, REFRESH_CHECK_INTERVAL).pipe(
            takeUntil(this.logoutSubject),
        ).subscribe(async () => {
            if (!this.cognitoToken.refreshToken || this.cognitoToken.refreshToken === 'undefined') {
                this.clearTokens().then(() => {
                    return;
                });
                return;
            } else if (!this.cognitoToken.expiresAt) {
                this.clearTokens().then(() => {
                    return;
                });
            } else {
                const now = new Date();
                const expiresAt = this.cognitoToken.expiresAt || now;
                const refreshTrigger = new Date(expiresAt.getTime() - REFRESH_EXPIRY_TRIGGER);

                if (now >= refreshTrigger) {
                    try {
                        await this.refreshTokenAuth(this.cognitoToken.refreshToken);
                    } catch (error) {
                        this.logout(); // probably don't need this
                    }
                }
            }
        });
    }

    private setTokens(response: CognitoIdentityServiceProvider.Types.InitiateAuthResponse): CognitoToken {
        const now = new Date();
        const expiresAt = new Date(now.getTime() + (response.AuthenticationResult?.ExpiresIn * 1000));
        let refreshToken = response.AuthenticationResult?.RefreshToken;
        if (this.cognitoToken !== null && this.cognitoToken.refreshToken !== null) {
            refreshToken = this.cognitoToken.refreshToken;
        }

        let loginExpiry = null;
        if (this.cognitoToken && !this.cognitoToken.loginExpiry) {
            loginExpiry = new Date((new Date()).getTime() + (24 * 60 * 60000));
            console.log('loginExpiry: CreateNewExpiry', loginExpiry);
        } else {
            console.log('loginExpiry: ReuseExpiry');
            loginExpiry = this.cognitoToken.loginExpiry;
        }

        if (loginExpiry <= new Date()) {
            console.log('loginExpiry: Already Expired - Recreate');
            loginExpiry = new Date((new Date()).getTime() + (24 * 60 * 60000));
            console.log('loginExpiry: CreateNewExpiry', loginExpiry);
        }

        return {
            accessToken: response.AuthenticationResult?.AccessToken,
            refreshToken,
            idToken: response.AuthenticationResult?.IdToken,
            expiresAt,
            loginExpiry,
        };
    }

    private saveTokens(): Promise<boolean> {
        const accessToken = this.settings.set('cognito:accessToken', this.cognitoToken.accessToken);
        const refreshToken = this.settings.set('cognito:refreshToken', this.cognitoToken.refreshToken);
        const idToken = this.settings.set('cognito:idToken', this.cognitoToken.idToken);
        const expiresAt = this.settings.set('cognito:expiresAt', this.cognitoToken.expiresAt.toISOString());
        const loginExpiryValue = this.cognitoToken.loginExpiry.toISOString();
        const loginExpiry = this.settings.set('login:expiresAt', loginExpiryValue);

        return new Promise((resolve, reject) => {
            Promise.all([accessToken, expiresAt, idToken, refreshToken, loginExpiry])
                .then(() => {
                    this.onReady.next();
                    resolve(true);
                })
                .catch(() => reject(false));
        });
    }

    public retrieveTokens(): Promise<CognitoToken> {
        const accessToken = this.settings.get('cognito:accessToken');
        const refreshToken = this.settings.get('cognito:refreshToken');
        const idToken = this.settings.get('cognito:idToken');
        const expiresAt = this.settings.get('cognito:expiresAt');
        const loginExpiry = this.settings.get('login:expiresAt');

        return new Promise((resolve, reject) => {
            Promise.all([accessToken, refreshToken, idToken, expiresAt, loginExpiry])
                .then((response: string[]) => {
                    const cognitoToken: CognitoToken = {
                        accessToken: response[0] ? response[0] : null,
                        refreshToken: response[1] ? response[1] : null,
                        idToken: response[2] ? response[2] : null,
                        expiresAt: response[3] ? new Date(response[3]) : new Date(),
                        loginExpiry: response[4] ? new Date(response[4]) : null,
                    };
                    resolve(cognitoToken);
                })
                .catch(() => reject(false))
                .finally(() => {
                    this.tokenRetrieved.next(true);
                });
        });
    }

    private clearTokens(): Promise<boolean> {
        const accessToken = this.settings.remove('cognito:accessToken');
        const refreshToken = this.settings.remove('cognito:refreshToken');
        const idToken = this.settings.remove('cognito:idToken');
        const expiresAt = this.settings.remove('cognito:expiresAt');
        const loginExpiry = this.settings.remove('login:expiresAt');

        if (this.refreshCheck) {
            this.refreshCheck.unsubscribe();
        }

        this.cognitoToken = {
            accessToken: null,
            expiresAt: new Date(),
            idToken: null,
            refreshToken: null,
            loginExpiry: new Date(),
        };

        return new Promise((resolve, reject) => {
            Promise.all([accessToken, refreshToken, idToken, expiresAt, loginExpiry])
                .then(() => {
                    console.log('clearTokens.then');
                    resolve(true);
                })
                .catch(() => {
                    console.log('clearTokens.error');
                    reject(false);
                })
                .finally(async () => {
                    const idTokenAfter = await this.settings.get('cognito:idToken');
                    console.log('clearTokens.finally');
                    console.log('clearTokens.idTokenAfter', idTokenAfter);
                });
        });
    }

    private setChallenge(response: CognitoIdentityServiceProvider.Types.InitiateAuthResponse): void {
        let userId = '';
        if (this.cognitoChallenge) {
            userId = this.cognitoChallenge.userId;
        }
        if (response.ChallengeParameters.hasOwnProperty('USER_ID_FOR_SRP')) {
            userId = response.ChallengeParameters.USER_ID_FOR_SRP;
        }

        this.cognitoChallenge = {
            initiateAuthResponse: response,
            username: this.cognitoLogin.AuthParameters.USERNAME,
            password: this.cognitoLogin.AuthParameters.PASSWORD,
            userId,
        };
    }

    private processCognitoLogin(error: AWSError, response: CognitoIdentityServiceProvider.Types.InitiateAuthResponse): void {
        if (error) {
            this.errorSubject.next(error);
        } else if (response && response.hasOwnProperty('AuthenticationResult')) {
            this.cognitoToken = this.setTokens(response);
            this.saveTokens().then(saveResponse => {
                if (saveResponse) {
                    this.tokenSubject.next(this.cognitoToken.idToken);
                    this.loginSubject.next(response);
                }
            }).catch(() => console.log('saveTokens: error'));
        } else if (response && response.hasOwnProperty('ChallengeParameters')) {
            this.setChallenge(response);
            this.loginChallengeSubject.next(response);
        }
    }

    private processForgotPasswordResponse(error: AWSError, response: CognitoIdentityServiceProvider.Types.ForgotPasswordResponse): void {
        if (error) {
            this.errorSubject.next(error);
        } else {
            this.forgotPasswordResponse = response;
            this.forgotPasswordSubject.next(response);
        }
    }

    public refreshTokenAuth(refreshToken?: string): Promise<any> {
        if (this.refreshTokenAttempt) {
            return this.refreshTokenAttempt;
        }

        return this.refreshTokenAuthApi(refreshToken);
    }

    public refreshTokenAuthApi(refreshToken?: string): Promise<string> {
        if (this.refreshTokenAttempt) {
            return this.refreshTokenAttempt;
        }

        if (!this.cognitoToken.refreshToken) {
            this.logout().then(() => {
                return Promise.resolve(null);
            });
        }

        this.refreshTokenAttempt = new Promise<string>((resolve, reject) => {

            if (!refreshToken) {
                refreshToken = this.cognitoToken.refreshToken;
            }

            const request: CognitoIdentityServiceProvider.Types.InitiateAuthRequest = {
                AuthFlow: 'REFRESH_TOKEN_AUTH',
                ClientId: this.getClientId(),
                AuthParameters: {
                    REFRESH_TOKEN: refreshToken,
                }
            };

            this.cognito.initiateAuth(request, (error, response) => {
                if (error) {
                    // Sentry.captureException(error);
                    this.errorSubject.next(error);
                    reject(error);
                } else if (response && response.hasOwnProperty('AuthenticationResult')) {
                    this.cognitoToken = this.setTokens(response);
                    this.saveTokens().then(() => {
                        this.loginSubject.next(response);
                        this.tokenSubject.next(this.cognitoToken.idToken);
                        resolve(this.cognitoToken.idToken);
                    });
                }
            });
        }).finally(() => {
            this.refreshTokenAttempt = null;
        });

        return this.refreshTokenAttempt;
    }

    private processConfirmForgotPassword(error: AWSError, response: CognitoIdentityServiceProvider.ConfirmForgotPasswordResponse): void {
        if (error) {
            this.errorSubject.next(error);
        } else {
            this.forgotPasswordRequest = null;
            this.forgotPasswordResponse = null;
            this.showForgotPasswordSuccess = true;
            this.forgotPasswordChangedSubject.next(response);
        }
    }

    private processChangePasswordResponse(error: AWSError, response: CognitoIdentityServiceProvider.ChangePasswordResponse): void {
        if (error) {
            if (error.message === 'Incorrect username or password.') {
                error.message = 'Incorrect password.';
            }
            this.errorSubject.next(error);
        } else {
            this.showChangedPasswordSuccess = true;
            this.passwordChangedSubject.next(response);
        }
    }

    public initiateAuth(username: string, password: string): Promise<AuthResponse> {
        return new Promise<AuthResponse>((resolve, reject) => {
            const request: CognitoIdentityServiceProvider.Types.InitiateAuthRequest = {
                AuthFlow: 'USER_PASSWORD_AUTH',
                ClientId: this.getClientId(),
                AuthParameters: {
                    USERNAME: username,
                    PASSWORD: password,
                },
            };

            this.clearTokens().then(() => {
                this.cognitoLogin = request;
                this.cognito.initiateAuth(request, (error, response) => {
                    this.processCognitoLogin(error, response);
                    if (error) {
                        return reject(Error(error.message));
                    } else if (response && response.hasOwnProperty('AuthenticationResult')) {
                        const authResponse: AuthResponse = {
                            action: 'redirect',
                            loggedIn: true,
                            challengeType: null,
                        };
                        return resolve(authResponse);
                    } else if (response && response.hasOwnProperty('ChallengeName')) {
                        let challengeType = null;
                        switch (response.ChallengeName) {
                            case 'SMS_MFA':
                                challengeType = 'mfa';
                                break;
                            case 'NEW_PASSWORD_REQUIRED':
                                challengeType = 'new-password';
                                break;
                            case 'SOFTWARE_TOKEN_MFA':
                                challengeType = 'totp';
                                break;
                            default:
                                reject('Invalid Challenge Type');
                                break;
                        }
                        if (challengeType) {
                            const authResponse: AuthResponse = {
                                action: 'challenge',
                                loggedIn: false,
                                challengeType,
                            };
                            return resolve(authResponse);
                        }
                    }
                    return reject();
                });
            });
        });
    }

    public respondToMfa(mfaCode: string, mfaType: string): Promise<AuthResponse> {
        return new Promise<AuthResponse>((resolve, reject) => {
            const cognitoChallenge = this.getCognitoChallenge();
            const request: CognitoIdentityServiceProvider.Types.RespondToAuthChallengeRequest = {
                ClientId: this.getClientId(),
                Session: cognitoChallenge.initiateAuthResponse.Session,
                ChallengeName: cognitoChallenge.initiateAuthResponse.ChallengeName,
                ChallengeResponses: {
                    USERNAME: cognitoChallenge.userId,
                    [mfaType]: mfaCode,
                }
            };
            this.clearTokens().then(() => {
                this.cognito.respondToAuthChallenge(request, (error, response) => {
                    this.processCognitoLogin(error, response);
                    if (error) {
                        reject(error.message);
                    } else if (response && response.hasOwnProperty('AuthenticationResult')) {
                        const authResponse: AuthResponse = {
                            action: 'redirect',
                            loggedIn: true
                        };
                        resolve(authResponse);
                    } else if (response && response.hasOwnProperty('ChallengeName')) {
                        let challengeType = null;
                        switch (response.ChallengeName) {
                            case 'SMS_MFA':
                                challengeType = 'mfa';
                                break;
                            case 'NEW_PASSWORD_REQUIRED':
                                challengeType = 'new-password';
                                break;
                            default:
                                reject('Invalid Challenge Type');
                                break;
                        }
                        if (challengeType) {
                            const authResponse: AuthResponse = {
                                action: 'challenge',
                                loggedIn: false,
                                challengeType,
                            };
                            resolve(authResponse);
                        }
                    }
                    return reject();
                });
            });
        });
    }

    public respondToNewPassword(password: string): Promise<AuthResponse> {
        return new Promise<AuthResponse>((resolve, reject) => {
            const cognitoChallenge = this.getCognitoChallenge();
            const request: CognitoIdentityServiceProvider.Types.RespondToAuthChallengeRequest = {
                ClientId: this.getClientId(),
                Session: cognitoChallenge.initiateAuthResponse.Session,
                ChallengeName: cognitoChallenge.initiateAuthResponse.ChallengeName,
                ChallengeResponses: {
                    USERNAME: cognitoChallenge.username,
                    NEW_PASSWORD: password,
                }
            };
            this.clearTokens().then(() => {
                this.cognito.respondToAuthChallenge(request, (error, response) => {
                    this.processCognitoLogin(error, response);
                    if (error) {
                        reject(error.message);
                    } else if (response && response.hasOwnProperty('AuthenticationResult')) {
                        const authResponse: AuthResponse = {
                            action: 'redirect',
                            loggedIn: true
                        };
                        resolve(authResponse);
                    } else if (response && response.hasOwnProperty('ChallengeName')) {
                        let challengeType = null;
                        switch (response.ChallengeName) {
                            case 'SMS_MFA':
                                challengeType = 'mfa';
                                break;
                            case 'NEW_PASSWORD_REQUIRED':
                                challengeType = 'new-password';
                                break;
                            default:
                                reject('Invalid Challenge Type');
                                break;
                        }
                        if (challengeType) {
                            const authResponse: AuthResponse = {
                                action: 'challenge',
                                loggedIn: false,
                                challengeType,
                            };
                            resolve(authResponse);
                        }
                    }
                    return reject();
                });
            });
        });
    }

    public forgotPassword(username: string): Promise<boolean> {
        return new Promise<boolean>(((resolve, reject) => {
            const request: CognitoIdentityServiceProvider.Types.ForgotPasswordRequest = {
                ClientId: this.getClientId(),
                Username: username,
            };

            this.clearTokens().then(() => {
                this.forgotPasswordRequest = request;
                this.cognito.forgotPassword(request, (error, response) => {
                    this.processForgotPasswordResponse(error, response);
                    if (error) {
                        reject(error.message);
                    } else {
                        resolve(true);
                    }
                });
            });
        }));
    }

    public confirmForgotPassword(verificationCode: string, password: string): Promise<boolean> {
        return new Promise<boolean>(((resolve, reject) => {
            const request: CognitoIdentityServiceProvider.Types.ConfirmForgotPasswordRequest = {
                ClientId: this.getClientId(),
                ConfirmationCode: verificationCode,
                Password: password,
                Username: this.getForgotPasswordRequestUsername(),
            };
            this.clearTokens().then(() => {
                this.cognito.confirmForgotPassword(request, (error, response) => {
                    this.processConfirmForgotPassword(error, response);
                    if (error) {
                        reject(error.message);
                    } else {
                        resolve(true);
                    }
                });
            });
        }));
    }

    public forgotPasswordResend(): Promise<boolean> {
        return new Promise<boolean>(((resolve, reject) => {
            const request = this.forgotPasswordRequest;
            this.cognito.forgotPassword(request, (error, response) => {
                this.processForgotPasswordResponse(error, response);
                if (error) {
                    reject(error.message);
                } else {
                    resolve(true);
                }
            });
        }));
    }

    public getCognitoChallenge(): CognitoChallenge {
        return this.cognitoChallenge;
    }

    public getClientId(): string {
        return this.clientId;
    }

    public async logout(): Promise<boolean> {
        this.logoutSubject.next(true);
        return new Promise<boolean>(resolve => {
            this.analytics.logEvent('logout');
            this.analytics.setUserProperty('role', null);
            this.cognitoUser = null;
            this.clearTokens().then((value) => {
                resolve(true);
            }).finally(() => resolve(true));
        });
    }

    public isLoggedIn(): boolean {
        return this.loggedIn;
    }

    public hasForgotPasswordResponse(): boolean {
        return !!this.forgotPasswordResponse;
    }

    public getLogin(): Observable<CognitoIdentityServiceProvider.Types.InitiateAuthResponse> {
        return this.loginSubject.asObservable();
    }

    public getError(): Observable<AWSError> {
        return this.errorSubject.asObservable();
    }

    public getLoginChallenge(): Observable<CognitoIdentityServiceProvider.Types.InitiateAuthResponse> {
        return this.loginChallengeSubject.asObservable();
    }

    public getToken(): Observable<string> {
        return this.tokenSubject.asObservable();
    }

    public getLogout(): Observable<boolean> {
        return this.logoutSubject.asObservable();
    }

    public getLogoutSubject(): Subject<boolean> {
        return this.logoutSubject;
    }

    public getForgotPassword(): Observable<CognitoIdentityServiceProvider.Types.ForgotPasswordResponse> {
        return this.forgotPasswordSubject.asObservable();
    }

    public getForgotPasswordChanged(): Observable<CognitoIdentityServiceProvider.ConfirmForgotPasswordResponse> {
        return this.forgotPasswordChangedSubject.asObservable();
    }

    public getPasswordChanged(): Observable<CognitoIdentityServiceProvider.ChangePasswordResponse> {
        return this.passwordChangedSubject.asObservable();
    }

    public clearForgotPassword(): void {
        this.forgotPasswordRequest = null;
        this.forgotPasswordResponse = null;
    }

    public getForgotPasswordRequestUsername(): string {
        return this.forgotPasswordRequest.Username;
    }

    public canShowForgotPasswordSuccess(): boolean {
        if (this.showForgotPasswordSuccess) {
            this.showForgotPasswordSuccess = false;
            return true;
        }
        return false;
    }

    public getAccessToken(): string {
        return this.cognitoToken.accessToken;
    }

    public getLoginExpiry(): Date {
        return this.cognitoToken ? this.cognitoToken.loginExpiry : null;
    }

    public changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
        return new Promise<boolean>(((resolve, reject) => {
            const request: CognitoIdentityServiceProvider.Types.ChangePasswordRequest = {
                AccessToken: this.getAccessToken(),
                PreviousPassword: oldPassword,
                ProposedPassword: newPassword,
            };

            this.cognito.changePassword(request, (error, response) => {
                this.processChangePasswordResponse(error, response);
                if (error) {
                    if (error.message === 'Incorrect username or password.') {
                        error.message = 'Incorrect password.';
                    }
                    reject(error.message);
                } else {
                    resolve(true);
                }
            });
        }));
    }

    public canShowChangedPasswordSuccess() {
        if (this.showChangedPasswordSuccess) {
            this.showChangedPasswordSuccess = false;
            return true;
        }
        return false;
    }

    public getCognitoUser(): Observable<CognitoIdentityServiceProvider.Types.GetUserResponse> {
        return this.cognitoUserSubject.asObservable();
    }

    public getCurrentCognitoUser(): CognitoIdentityServiceProvider.Types.GetUserResponse {
        return this.cognitoUser;
    }

    public setupSoftwareToken(): void {
        const params: CognitoIdentityServiceProvider.Types.AssociateSoftwareTokenRequest = {
            AccessToken: this.cognitoToken.accessToken,
        };

        this.cognito.associateSoftwareToken(params, (err, data) => {
            if (err) {
                console.error(err);
            } else {
                console.log(data);
            }
        });
    }

    public verifySoftwareToken(userCode: string) {
        const params = {
            UserCode: userCode,
            AccessToken: this.cognitoToken.accessToken,
        };

        this.cognito.verifySoftwareToken(params, (err, data) => {
            if (err) {
                console.error(err);
            } else {
                console.log(data);
            }
        });
    }

    public ready(): Promise<void> {
        return new Promise<void>(resolve => this.onReady.subscribe(() => resolve()));
    }
}
