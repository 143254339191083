import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthResponse, CognitoAuthenticationService } from '../../services/cognito-authentication.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-login-mfa-page',
    template: '',
    styleUrls: ['login-mfa.scss'],
})

export abstract class LoginMfaPage {

    public mfaCode = '';

    public errors: string[] = [];
    public hasError = false;
    public isLoading = false;

    private inputChange = new Subject();

    public mfaType: 'totp' | 'sms';

    constructor(
        private cognitoAuth: CognitoAuthenticationService,
        private navCtrl: NavController,
        private router: Router,
    ) {
        if (this.router.url === '/totp') {
            this.mfaType = 'totp';
        } else {
            this.mfaType = 'sms';
        }
        this.inputChange.subscribe(() => {
            if (this.mfaCode.length >= 6) {
                this.isLoading = true;
                this.respondToAuthChallengeRequest();
            }
        });
    }

    public onChange() {
        this.inputChange.next();
    }

    goBackToPreviousPage() {
        this.navCtrl.pop();
    }

    private addError(error: string) {
        this.errors.push(error);
        this.hasError = true;
    }

    private clearErrors() {
        this.errors = [];
        this.hasError = false;
    }

    public respondToAuthChallengeRequest() {

        this.clearErrors();

        if (this.mfaCode === '') {
            this.addError('MFA Code Required.');
            return;
        }

        if (this.mfaCode.length !== 6) {
            this.addError('MFA Code Must Be 6 Characters.');
            return;
        }

        this.clearErrors();
        const mfaType = this.mfaType === 'sms' ? 'SMS_MFA_CODE' : 'SOFTWARE_TOKEN_MFA_CODE';
        this.cognitoAuth.respondToMfa(this.mfaCode, mfaType)
            .then((authResponse: AuthResponse) => {
                if (authResponse.loggedIn) {
                    this.router.navigate(['/dashboard']);
                } else {
                    switch (authResponse.challengeType) {
                        case 'new-password':
                            this.router.navigate(['/change-temporary-password']);
                            break;
                        case 'mfa':
                            this.router.navigate(['/mfa']);
                            break;
                        case 'totp':
                            this.router.navigate(['/totp']);
                            break;
                        default:
                            this.addError('Invalid Authorisation Response');
                            this.isLoading = false;
                            break;
                    }
                }
            })
            .catch(error => {
                this.addError(error);
                this.isLoading = false;
            });
    }

    public ionViewWillEnter() {
        this.isLoading = false;
        this.mfaCode = '';

        if (!this.cognitoAuth.getCognitoChallenge()) {
            this.router.navigate(['/login']);
        }
    }
}
