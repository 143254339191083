import { Component, ViewChild, ElementRef } from '@angular/core';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { Router } from '@angular/router';
import { AuthResponse, CognitoAuthenticationService } from '../../services/cognito-authentication.service';

@Component({
    selector: 'app-change-temporary-password-page',
    template: '',
    styleUrls: ['change-temporary-password.scss'],
})

export abstract class ChangeTemporaryPassword {

    public passwordTemporary = '';
    public passwordNew = '';
    public passwordNewConfirm = '';

    public errors: string[] = [];
    public hasError = false;
    public isLoading = false;

    constructor(
        private cognitoAuth: CognitoAuthenticationService,
        private router: Router,
    ) {
    }

    public addError(error: string) {
        this.errors.push(error);
        this.hasError = true;
    }

    public clearErrors() {
        this.errors = [];
        this.hasError = false;
    }

    public confirmNewPassword() {

        this.clearErrors();

        if (this.passwordTemporary === '' || this.passwordNew === '' || this.passwordNewConfirm === '') {
            this.addError('All Fields Required.');
            return;
        }

        const cognitoChallenge = this.cognitoAuth.getCognitoChallenge();

        if (this.passwordTemporary !== cognitoChallenge.password) {
            this.addError('Temporary Password Incorrect.');
            return;
        }

        if (this.passwordNew !== this.passwordNewConfirm) {
            this.addError('New Passwords Do Not Match.');
            return;
        }

        this.clearErrors();
        this.isLoading = true;
        this.cognitoAuth.respondToNewPassword(this.passwordNew)
            .then((authResponse: AuthResponse) => {
                if (authResponse.loggedIn) {
                    this.router.navigate(['/dashboard']);
                } else {
                    switch (authResponse.challengeType) {
                        case 'new-password':
                            this.router.navigate(['/change-temporary-password']);
                            break;
                        case 'mfa':
                            this.router.navigate(['/mfa']);
                            break;
                        case 'totp':
                            this.router.navigate(['/totp']);
                            break;
                        default:
                            this.addError('Invalid Authorisation Response');
                            this.isLoading = false;
                            break;
                    }
                }
            })
            .catch(error => {
                this.addError(error);
                this.isLoading = false;
            });
    }

    public ionViewWillEnter() {
        if (!this.cognitoAuth.getCognitoChallenge()) {
            this.router.navigate(['/login']);
        }
    }
}
