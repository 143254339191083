import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, AlertController, LoadingController, Platform } from '@ionic/angular';
import { CognitoAuthenticationService } from '../../services/cognito-authentication.service';
import { UserService } from '../../services/user.service';
import { User } from '../../services/models/user';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { File as CordovaFile, IFile } from '@ionic-native/file/ngx';
import { WebFile } from '../../helpers/web-file';
import { BiometricService } from '../../services/biometric.service';

@Component({
    selector: 'app-my-profile-page',
    templateUrl: 'my-profile.html',
    styleUrls: ['my-profile.scss'],
})

export class MyProfilePage implements AfterViewInit, OnInit {

    public onViewWillLeave: Subject<void> = new Subject<void>();
    public user: User = null;

    public actionSheet: HTMLIonActionSheetElement = null;
    private attachment: WebFile = null;
    public imageInput: HTMLInputElement;
    @ViewChild('imageInput', { static: false }) public imageUploadRef: ElementRef<HTMLInputElement>;

    constructor(
        private actionSheetController: ActionSheetController,
        private alertController: AlertController,
        private biometric: BiometricService,
        private camera: Camera,
        private cognitoAuth: CognitoAuthenticationService,
        private file: CordovaFile,
        private loading: LoadingController,
        private platform: Platform,
        private router: Router,
        private userService: UserService,
    ) {

    }

    public ngOnInit(): void {
    }

    public ionViewWillEnter() {
        this.userService.getMe().pipe(
            takeUntil(this.onViewWillLeave),
        ).subscribe(user => {
            this.user = user;
        });
        this.userService.fetchMe();
    }

    public ionViewWillLeave() {
        this.onViewWillLeave.next();
    }

    public resetPassword() {
        this.router.navigate(['/my-profile/change-password']);
    }

    async logout() {
        const alert = await this.alertController.create({
            cssClass: 'custom-alert',
            message: 'Are you sure you want to log out?',
            buttons: [
            {
                text: '',
                role: 'cancel',
                cssClass: 'close',
                handler: (blah) => {
                  console.log('Confirm Cancel: blah');
                }
            },
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                console.log('Confirm Cancel: blah');
              }
            }, {
              text: 'Log Out',
              cssClass: 'primary',
              handler: async () => {
                  const loading = await this.loading.create();
                  await loading.present();
                  this.cognitoAuth.logout().finally(async () => {
                      this.router.navigate(['login']).then(async _ => loading.dismiss());
                  });
              }
            }
          ]
        });

        await alert.present();
    }

    private async androidTakePhoto() {
        this.biometric.cameraTriggered();

        const options: CameraOptions = {
            quality: 75,
            destinationType: this.camera.DestinationType.FILE_URI,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            targetWidth: 1280,
            targetHeight: 1280,
        };

        this.camera.getPicture(options).then(async (tempFile: string) => {
            const tempFilename = tempFile.substr(tempFile.lastIndexOf('/') + 1);
            const tempBaseFilesystemPath = tempFile.substr(0, tempFile.lastIndexOf('/') + 1);
            const directory = await this.file.resolveDirectoryUrl(tempBaseFilesystemPath);
            const file = await this.file.getFile(directory, tempFilename, {});

            const getArrayBufferForFile = input => new Promise<ArrayBuffer>((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.onload = event => resolve(event.target.result as ArrayBuffer);
                fileReader.readAsArrayBuffer(input);
            });

            file.file(async (resFile: IFile) => {
                const buffer = await getArrayBufferForFile(resFile);

                this.attachment = new WebFile([buffer], resFile.name, { lastModified: Date.now() });

                this.biometric.cameraCompleted();

                this.uploadIcon();

                if (this.actionSheet) {
                    this.actionSheet.dismiss();
                }
            });
        });
    }

    private selectPhoto() {
        if (this.platform.is('android')) {
            this.biometric.cameraTriggered();
        }
        this.imageInput.click();
    }

    private presentAddImageAction() {
        this.actionSheetController.create({
            buttons: [{
                text: 'Photo Library',
                icon: 'image-outline',
                handler: () => this.selectPhoto()
            }, {
                text: 'Take Photo',
                icon: 'camera-outline',
                handler: () => this.androidTakePhoto()
            }]
        }).then(async (actionSheet) => {
            this.actionSheet = actionSheet;
            await actionSheet.present();
        });
    }

    public async editIcon() {
        if (this.platform.is('android')) {
            await this.presentAddImageAction();
        } else {
            this.selectPhoto();
        }
    }

    public ngAfterViewInit() {
        this.imageInput = this.imageUploadRef.nativeElement;
    }

    public onImageAdded() {
        if (this.actionSheet) {
            this.actionSheet.dismiss();
        }
        this.attachment = this.imageInput.files[0];
        if (this.platform.is('android')) {
            this.biometric.cameraCompleted();
        }
        this.uploadIcon();
    }

    private async uploadIcon() {
        if (!this.attachment) {
            return;
        }
        await this.userService.updateProfile(this.attachment);
    }

    public setupTotp() {
        return this.cognitoAuth.setupSoftwareToken();
    }

    public async verifyTotp() {
        const alert = await this.alertController.create({
            header: 'TOTP Verification',
            message: 'Enter TOTP Verification Code',
            inputs: [
                {
                    name: 'code',
                    type: 'text',
                    placeholder: 'TOTP Code'
                }
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        alert.dismiss();
                    }
                }, {
                    text: 'Ok',
                    handler: data => {
                        this.cognitoAuth.verifySoftwareToken(data.code);
                    }
                }
            ]
        });

        await alert.present();
    }
}
